// import Components from './Components';
// import Apps from './Applications';

const routes = [
  // general
  {
    path: '',
    name: 'dashboard',
    props: true,
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'dashboard-home',
        props: true,
        component: () => import(/* webpackChunkName: "Home" */ '@/view/dashboard/DashboardHome.vue'),
      },
      {
        path: 'order',
        name: 'order',
        props: true,
        component: () => import(/* webpackChunkName: "Order" */ '@/view/dashboard/OrderList.vue'),
      },
      {
        path: 'test',
        name: 'test',
        props: true,
        // component: () => import(/* webpackChunkName: "Test" */ '@/view/dashboard/Test.vue'),
        component: () => import(/* webpackChunkName: "Home" */ '@/view/adminSettings/Test.vue'),
      },
      {
        path: 'order-list/:orderId?/:orderType?/:memberId?',
        name: 'order-list',
        props: true,

        component: () => import(/* webpackChunkName: "OrderList" */ '@/view/dashboard/OrderList.vue'),
      },
      {
        path: 'member-list/:memberId?',
        name: 'member-list',
        component: () => import(/* webpackChunkName: "MemberList" */ '@/view/dashboard/MemberList.vue'),
      },
      {
        path: 'rebate-list',
        name: 'rebate-list',
        component: () => import(/* RebateList */ '@/view/dashboard/RebateList.vue')
      },
      {
        path: 'transaction-log/:memberId',
        name: 'transaction-log',
        component: () => import(/* webpackChunkName: "MemberList" */ '@/view/dashboard/MemberTrxLog.vue'),
      },
      {
        path: 'enquiry-list',
        name: 'enquiry-list',
        component: () => import(/* webpackChunkName: "Enquiry" */ '@/view/dashboard/EnquiryList.vue')
      },
      {
        path: 'admin-users',
        name: 'admin-users',
        component: () => import(/* admin users */ '@/view/adminSettings/AdminUsers.vue')
      },
      // {
      //   path: 'bank-settings',
      //   name: 'bank-settings',
      //   component: () => import(/* bank settings */ '@/view/adminSettings/BankSettings.vue')
      // },
      {
        path: 'cash-subjects',
        name: 'cash-subjects',
        component: () => import(/* cash subjects */ '@/view/adminSettings/CashSubjectList.vue')
      },
      {
        path: 'product-settings',
        name: 'product-settings',
        component: () => import(/* product settings */ '@/view/adminSettings/ProductSettings.vue')
      },
      {
        path: 'login-approval',
        name: 'login-approval',
        component: () => import(/* login approval */ '@/view/adminSettings/LoginApproval.vue')
      },
      {
        path: 'complaints',
        name: 'complaints',
        component: () => import(/* complaints */ '@/view/adminSettings/ComplaintList.vue')
      },
      {
        path: 'source',
        name: 'source',
        component: () => import(/* source */ '@/view/adminSettings/SourceList.vue')
      },
      
    ],
  },
  // minigame
  {
    path: '/minigame',
    name: 'minigame',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'member-code',
        name: 'member-code',
        component: () => import(/* webpackChunkName: "LuckySpinMember" */ '@/view/dashboard/LuckySpinMember.vue'),
      },
      {
        path: 'luckyspin-logs',
        name: 'luckyspin-logs',
        component: () => import(/* webpackChunkName: "LuckySpinLog" */ '@/view/dashboard/LuckySpinLog.vue'),
      }
    ]
  },
   // minigame settings
   {
    path: '/minigame-settings',
    name: 'minigame-settings',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'luckyspin',
        name: 'luckyspin',
        component: () => import(/* webpackChunkName: "LuckySpinMember" */ '@/view/dashboard/LuckySpinItems.vue'),
      }
    ]
  },
  // bank
  {
    path: '/bank',
    name: 'bank',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'bank-list',
        name: 'bank-list',
        component: () => import(/* webpackChunkName: "BankList" */ '@/view/dashboard/BankList.vue'),
      },
      {
        path: 'bank-logs',
        name: 'bank-logs',
        component: () => import(/* webpackChunkName: "BankLog" */ '@/view/dashboard/BankLog.vue')
      },
    ]
  },
  // product
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'product-balance',
        name: 'product-balance',
        component: () => import(/* product balance */ '@/view/dashboard/ProductBalance.vue')
      },
      {
        path: 'product-log',
        name: 'product-log',
        component: () => import(/* product log */ '@/view/dashboard/ProductLog.vue')
      },
    ]
  },
  // report
  {
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'bank-report',
        name: 'bank-report',
        component: () => import(/* webpackChunkName: "BankReport" */ '@/view/reports/BankReport.vue'),
      },
      {
        path: 'product-report',
        name: 'product-report',
        component: () => import(/* webpackChunkName: "ProductReport" */ '@/view/reports/ProductReport.vue'),
      },
      {
        path: 'cs-transaction-report',
        name: 'cs-transaction-report',
        component: () => import(/* webpackChunkName: "TransactionReport" */ '@/view/reports/CSTransactionReport.vue'),
      },
      {
        path: 'bonus-report',
        name: 'bonus-report',
        component: () => import(/* webpackChunkName: "BonusReport" */ '@/view/reports/BonusReport.vue'),
      },
      {
        path: 'sales-report',
        name: 'sales-report',
        component: () => import(/* webpackChunkName: "SalesReport" */ '@/view/reports/SalesReport.vue'),
      },
      {
        path: 'cs-sales-report',
        name: 'cs-sales-report',
        component: () => import(/* webpackChunkName: "CSSalesReport" */ '@/view/reports/CSReport.vue'),
      },
      {
        path: 'member-report',
        name: 'member-report',
        component: () => import(/* webpackChunkName: "MemberReport" */ '@/view/reports/MemberReport.vue'),
      },
      {
        path: 'rebate-report',
        name: 'rebate-report',
        component: () => import(/* webpackChunkName: "RebateMemberReport" */ '@/view/reports/RebateReport.vue'),
      },
      {
        path: 'source-report',
        name: 'source-report',
        component: () => import(/* webpackChunkName: "SourceReport" */ '@/view/reports/SourceReport.vue'),
      },
      {
        path: 'spin-report',
        name: 'spin-report',
        component: () => import(/* webpackChunkName: "SpinReport" */ '@/view/reports/SpinReport.vue'),
      },
      {
        path: 'report-generator',
        name: 'report-generator',
        component: () => import(/* webpackChunkName: "SpinReport" */ '@/view/reports/ReportGenerator.vue'),
      }
    ]
  },
  // bankbot
  {
    path: '/bankbot',
    name: 'bankbot',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'bankbot-list',
        name: 'bankbot-list',
        component: () => import(/* webpackChunkName: "BankList" */ '@/view/dashboard/BankBotList.vue'),
      },
      {
        path: 'bankbot-log',
        name: 'bankbot-log',
        component: () => import(/* webpackChunkName: "BankLog" */ '@/view/dashboard/BankBotLog.vue')
      },
    ]
  },
  // member-settings
  {
    path: '/member-settings',
    name: 'member-settings',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'member-levels',
        name: 'member-levels',
        component: () => import(/* webpackChunkName: "member levels" */ '@/view/adminSettings/MemberLevelsList.vue')
      },
      {
        path: 'rebate-settings',
        name: 'rebate-settings',
        component: () => import(/* webpackChunkName: "rebate settings" */ '@/view/adminSettings/RebateSettingsList.vue')
      },
      {
        path: 'member-sources',
        name: 'member-sources',
        component: () => import(/* webpackChunkName: "member sources" */ '@/view/adminSettings/MemberSourcesList.vue')
      },
      {
        path: 'affiliate-settings',
        name: 'affiliate-settings',
        component: () => import(/* webpackChunkName: "affiliate settings" */ '@/view/adminSettings/AffiliateSettingsList.vue')
      },
      {
        path: 'game-credit-adjustments',
        name: 'game-credit-adjustments',
        component: () => import(/* webpackChunkName: "game credit adjustments" */ '@/view/adminSettings/GameCreditAdjustments.vue')
      },
    ]
  },
  // website-settings
  {
    path: '/web-settings',
    name: 'web-settings',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'promotions',
        name: 'promotions',
        component: () => import(/* webpackChunkName: "Promotions" */ '@/view/adminSettings/PromotionList.vue')
      },
      {
        path: 'reviews',
        name: 'reviews',
        component: () => import(/* webpackChunkName: "Reviews" */ '@/view/adminSettings/ReviewList.vue')
      },
      {
        path: 'luckydraw',
        name: 'luckydraw',
        component: () => import(/* webpackChunkName: "Luckyspin" */ '@/view/adminSettings/LuckyDrawList.vue')
      },
      {
        path: 'newsnevents',
        name: 'newsnevents',
        component: () => import(/* webpackChunkName: "News n events" */ '@/view/adminSettings/NewsnEventsList.vue')
      },
      {
        path: 'web-general-settings',
        name: 'web-general-settings',
        component: () => import(/* webpackChunkName: "general settings" */ '@/view/adminSettings/GeneralSettings.vue')
      },
    ]
  },
  // dashboard-settings
  {
    path: '/dashboard-settings',
    name: 'dashboard-settings',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'dashboard-general-settings',
        name: 'dashboard-general-settings',
        component: () => import(/* webpackChunkName: "general settings" */ '@/view/adminSettings/DashboardSettings.vue')
      },
    ]
  },
  // ...Components,
  // ...Apps,
];

export default routes;
