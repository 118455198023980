import mutations from './mutations';
import Cookies from 'js-cookie';

const state = () => ({
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
  access: null,
  refresh: null,
  permissions: null
});

const actions = {
  async login({ commit }, data) {
    try {
      commit('loginBegin');
      Cookies.set('logedIn', true);
      Cookies.set('access', data.access);
      Cookies.set('refresh', data.refresh);
      if (data.username) {
        Cookies.set('username', data.username);
      }
      if (data.permissions) {
        Cookies.set('permissions', JSON.stringify(data.permissions));
      }
      return commit('loginSuccess', true, data);
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin');
      Cookies.set('logedIn', false);
      Cookies.remove('access');
      Cookies.remove('refresh');
      Cookies.remove('permissions');
      Cookies.remove('bankBotAccess');
      Cookies.remove('username');
      commit('logoutSuccess', null);
    } catch (err) {
      commit('logoutErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
