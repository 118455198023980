import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
// import store from '@/vuex/store';
import Cookies from "js-cookie";

function isLoggedIn() {
  const token = Cookies.get('logedIn');
  return (token === 'true' ); 
}

const routes = [
  {
    name: 'landing',
    path: '/',
    meta: { auth: false },
    // component: () => import(/* webpackChunkName: "admin" */ '@/view/WelcomeMessage.vue'),
    component: () => import(/* webpackChunkName: "base" */ '@/view/pages/knowledgebase/Index.vue'),
  },
  {
    name: 'home',
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: true },
    props: true
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: false },
    // Add a beforeEnter guard to prevent logged-in users from accessing the login page
    beforeEnter: (to, from, next) => {
      if (isLoggedIn()) {
        // If the user is already logged in, redirect to the home page
        next({ name: 'dashboard-home' });
      } else {
        // If the user is not logged in, allow them to access the login page
        next();
      }
    }
  },
  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "base" */ '@/view/pages/knowledgebase/Index.vue'),
  },
];

const router = createRouter({
  mode: 'history',
  // history: createWebHistory(),
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
      // ? process.env.BASE_URL
      // : process.env.BASE_URL,
  ),

  linkExactActiveClass: 'active',
  routes,
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

router.beforeEach((to, from, next) => {
// router.beforeEach((to) => {
    window.scrollTo(0, 0); // reset scroll position to top of page
     // Check if the route requires authentication
    if (to.meta.auth && !isLoggedIn() && to.name !== 'login') {
      // If the user is not authenticated and not on the login page, redirect to the login page
      next({ name: 'login' }); // Redirect to the login route
    } else {
      // If the user is authenticated or the route does not require authentication, proceed
      next();
    }
    // trying to access auth route but not logged in
    // if (to.meta.auth && store.state.auth.login == "false") {
    // if (store.state.auth.login == "false") {
    //   next({
    //     name: 'login',
    //     // query: { redirect: to.fullPath }
    //   })
    // }

    // else {
    //   next();
    // }
    // if (to.meta.auth && store.state.auth.login == "false") {
    //   // this route requires auth, check if logged in
    //   // if not, redirect to login page.
    //   return {
    //     name: 'login',
    //     // save the location we were at to come back later
    //     query: { redirect: to.fullPath },
    //   }
    // }
    // if (to.meta.auth && store.state.auth.login == "false" && to.name !== 'login') {
    //   // If the user is not authenticated, redirect to the login page
    //   next({ name: 'login' });
    // } else {
    //   // If the user is authenticated or the route does not require authentication, proceed
    //   next();
    // }
});

export default router;
