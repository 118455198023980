export default {
  loginBegin(state) {
    state.login = true;
  },
  loginSuccess(state, isLoggedIn, data) {
    state.loading = false;
    state.login = isLoggedIn;
    state.access = data.access;
    state.refresh = data.refresh;
    state.permissions = data.permissions
  },

  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
    state.access = data;
    state.refresh = data;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
