<template>
  <ModalStyled
    :title="title"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="onOk"
    @cancel="onCancel"
    :type="color ? this.type : false"
    :width="width"
    :class="className"
    :footer="null"
    :id="id"
  >
    <!-- <template #footer>
      <slot name="footer">
        <sdButton
          type="white"
          :outlined="color ? false : true"
          key="back"
          @click="onCancel"
        >
          Return
        </sdButton>
        <sdButton
          key="submit"
          :type="color ? 'white' : type"
          :outlined="type !== 'white' ? false : true"
          :loading="confirmLoading"
          @click="handleOk"
        >
          Submit
        </sdButton>
      </slot>
    </template> -->
    

    <slot name="title"> </slot>

    <slot></slot>
  </ModalStyled>
</template>

<script>
import { ModalStyled } from "./styled";
import VueTypes from "vue-types";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Modal",
  components: {
    ModalStyled,
  },
  props: {
    onCancel: VueTypes.func,
    onOk: VueTypes.func,
    visible: VueTypes.bool.def(false),
    confirmLoading: VueTypes.bool.def(false),
    title: VueTypes.string,
    id: VueTypes.string,
    className: VueTypes.string.def("ninjadash-modal"),
    type: VueTypes.oneOf([
      "primary",
      "secondary",
      "success",
      "error",
      "danger",
      "info",
      "white",
      "warning",
    ]).def("white"),
    footer: VueTypes.oneOf([null]),
    width: VueTypes.number.def(620),
    color: VueTypes.oneOfType([VueTypes.bool, VueTypes.string]).def(false),
  },
  // data() {
  //   return {
  //     className: this.class,
  //   };
  // },
});
</script>
<style lang="scss">
.ant-modal-close-x {
  line-height: 42px;
}
.actionModal {
  .ant-modal-header {
    background-color: #0C192C;
    padding: 10px 30px;

    .ant-modal-title {
      /* float: right;
      right: 50px;
      position: absolute; */
      color: #FFFFFF;
      text-transform: uppercase;
    }
  }
  .ant-modal-close-icon {
    svg {
      fill: #FFFFFF;
    }
  }
}

.filterModal {
  z-index: 99;
    .ant-modal-header {
      background-color: #4B93FF;
      padding: 10px 30px;
  
      .ant-modal-title {
        /* float: left; */
        color: #FFFFFF;
        text-transform: uppercase;
      }
    }
    .ant-modal-close-icon {
      svg {
        fill: #FFFFFF;
      }
    }
    .multi {
      display: grid;
      grid-template-columns: 49% 49%;
      gap: 10px;
      .ant-form-item {
        flex: 1;
      }
    }
  }

.addModal {
  .ant-modal-header {
    background-color: #6C63FF;
    padding: 10px 30px;

    .ant-modal-title {
      /* float: right; */
      /* right: 50px; */
      /* position: absolute; */
      color: #FFFFFF;
      text-transform: uppercase;
    }
  }
  .ant-modal-close-icon {
    svg {
      fill: #FFFFFF;
    }
  }
  &.single {
    .ant-modal-title {
        /* right: 50% !important;
        transform: translate(50%, -50%) !important; */
      }
  }
  .modal-add-btn {
    border: none;
    background: #6C63FF;
    border-radius: 4px;
    padding: 6px 14px;
    color: white;
    cursor: pointer;
  }
}
.resetModal {
  .ant-modal-header {
    background-color: #FF6163;
    padding: 10px 30px;

    .ant-modal-title {
      /* float: right; */
      /* right: 50px; */
      /* position: absolute; */
      color: #FFFFFF;
      text-transform: uppercase;
    }
  }
  .ant-modal-close-icon {
    svg {
      fill: #FFFFFF;
    }
  }
  .reset-card {
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .password {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .reset-buttons {
    text-align: center;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    button {
      flex: 1;
      padding: 11px;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      &.cancel {
        background-color: #EF476F;
      }
      &.submit {
        background-color: #0AD69F;
      }
    }
  }
}
.detailedTrxModal {
  .top-input-wrapper {
    display: flex;
    gap: 10px;
  }
  .bottom-input-wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    .bottom-left, .bottom-right {
      flex: 1;
      .record-grid {
        display: grid;
        grid-template-columns: 49% 49%;
        column-gap: 10px;
      }
    }
  }
}
</style>